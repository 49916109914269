<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <h2 class="text-center userIn">用户登录</h2>
                <div class="mb-3">
                    <label for="userId" class="form-label">请输入用户ID：</label>
                    <input type="text" class="form-control" id="userId" v-model="userId" placeholder="请输入用户ID（6~12位）" />
                </div>
                <button class="btn btn-primary w-100" @click="handleLogin">登录</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userId: '',
        };
    },
    methods: {
        async handleLogin() {
            if (this.userId.length < 6 || this.userId.length > 12) {
                alert('用户ID必须在6到12位之间');
                return;
            }
            try {
                const response = await fetch('https://154.44.25.22/userId.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: this.userId }),
                });
                const result = await response.json();
                if (result.success) {
                    // 触发事件，将 userId 传递给父组件
                    this.$emit('login-success', this.userId);
                } else {
                    alert(result.message);
                }
            } catch (error) {
                console.error('登录失败:', error);
            }
        },
    },

};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.userIn {
    width: 100%;
}

.row {
    width: 100%;
    padding: 50px;
    background-color: #fff;
    border-radius: 25px;
}

.mb-3 {
    display: flex;
}

.mb-3 label {
    width: 50%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#userId {
    width: 69.5%;
    text-align: center;
}

h2 {
    margin-bottom: 30px;
}
</style>
