<template>
    <div class="update-log">
        <!-- 合球配置 -->
        <div class="help">
            <label for="ballConfig" class="form-label subtitle">合球配置:</label>
            <select v-model="params.ballConfig" class="form-select dropDown">
                <option value="selfBuilt">自建配置</option>
                <option value="selfWar">实战配置</option>
                <option value="selfAdjusting">自调配置</option>
            </select>
        </div>

        <!-- 显示设置 -->
        <div class="help">
            <label class="form-label subtitle">键位设置:</label>
            <div class="show-install">
                <div>
                    <input type="radio" v-model="params.displaySetting" value="显示" id="setting1"
                        class="form-check-input" />
                    <label for="setting1" class="form-check-label">显示</label>
                </div>
                <div>
                    <input type="radio" v-model="params.displaySetting" value="隐藏" id="setting2"
                        class="form-check-input" />
                    <label for="setting2" class="form-check-label">隐藏</label>
                </div>
                <button class="btn btn-primary edit" @click="edit()">编辑</button>
            </div>
        </div>

        <!-- 视野大小 -->
        <div class="help">
            <label for="fieldOfView" class="form-label subtitle">视野大小:</label>
            <div class="d-flex align-items-center">
                <button @click="adjustValue('fieldOfView', -1)" class="btn btn-primary btn-vsm me-2">-</button>
                <input type="range" v-model.number="params.fieldOfView" min="1" max="100"
                    class="form-range dataStrip" />
                <button @click="adjustValue('fieldOfView', 1)" class="btn btn-primary btn-vsm ms-2">+</button>
            </div>
            <span class="value-display">{{ params.fieldOfView + '%' }}</span>
        </div>

        <!-- 辅助功能 -->
        <div class="help">
            <label class="form-label subtitle">辅助功能:</label>
            <div class="show-install">
                <label v-for="(feature, index) in features" :key="feature" class="form-check">
                    <div>
                        <input type="checkbox" @change="featureChange(feature, index)" :value="feature" v-model="params.features" class="form-check-input" /> {{
                            feature }}
                    </div>
                </label>
            </div>
        </div>

        <button @click="submit" class="btn btn-primary btn-lg btn-block save">加载</button>

        <MyModal :visible="modalVisible" :message="modalMessage" @close="modalVisible = false" />
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import MyModal from '../public/MyModal';

export default {
    name: 'ParameterConfig',
    components: { MyModal },
    props: {
        username: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const params = ref({
            ballConfig: 'selfBuilt', // 默认值
            fieldOfView: 50,
            displaySetting: '显示', // 默认值
            features: [],
            username: props.username,
        });

        const features = ref(['摇杆解限', '摇杆离心', '粘合动画', '背景变黑', '辅助线延长']);

        const modalVisible = ref(false);
        const modalMessage = ref('');

        // 监听 ballConfig 的变化
        watch(() => params.value.ballConfig, (newValue) => {
            emit('update-ball-config', newValue);
        });

        const adjustValue = (key, delta) => {
            const newValue = params.value[key] + delta;
            if (newValue >= 1 && newValue <= 100) {
                params.value[key] = newValue;
            }
        };

        const featureChange = (feature, index) => {
            var arr = JSON.stringify(params.value['features']);
            arr = JSON.parse(arr);
            var isSelected = false;
            for (var i = 0; i < arr.length; i++) {
                if (feature == arr[i]) {
                    isSelected = true;
                    break;
                }
            }
            const platform = window.platform;
            if (platform != null && platform.featureChange != null) {
                platform.featureChange(index, isSelected);
            }
        };

        const edit = () => {
            const platform = window.platform;
            if (platform != null && platform.generalEdit != null) {
                platform.generalEdit();
            }
        }

        const submit = () => {
            const platform = window.platform;
            if (platform != null && platform.generalSubmit != null) {
                var configJson = JSON.stringify({
                    ballConfig: params.value['ballConfig'],
                    displaySetting: params.value['displaySetting'],
                    fieldOfView: params.value['fieldOfView'],
                    features: params.value['features']
                });
                platform.generalSubmit(configJson);
            }

            // 这里可以替换为实际用户 ID
            console.log(params.value);

            // 发送请求保存数据
            fetch('https://154.44.25.22/sub.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params.value) // 直接将 params.value 转换为 JSON 字符串
            })
                .then(response => response.json())
                .then(data => {
                    modalMessage.value = '加载成功';
                    modalVisible.value = true;
                    console.log('成功:', data);
                })
                .catch(error => {
                    modalMessage.value = '加载失败';
                    modalVisible.value = true;
                    console.error('错误:', error);
                });
        };


        // 从后端获取用户配置数据
        const fetchUserConfig = async () => {
            console.log(params.value.username);
            try {
                const response = await axios.get(`https://154.44.25.22/generalLayout.php?id=${params.value.username}`); // 根据 ID 获取数据

                if (response.data.success) {
                    const userConfig = response.data.data[0].data; // 假设只返回一个对象

                    // 设置 ballConfig 的值，确保它与下拉选项匹配
                    params.value.ballConfig = userConfig.ballConfig || 'selfBuilt'; // 默认值为 'selfBuilt'
                    params.value.fieldOfView = userConfig.fieldOfView || 50; // 设置默认值
                    params.value.displaySetting = userConfig.displaySetting || '显示'; // 设置默认值
                    params.value.features = userConfig.features || []; // 设置默认值
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // 在组件挂载时获取用户配置
        onMounted(fetchUserConfig);


        return {
            params,
            features,
            adjustValue,
            featureChange,
            edit,
            submit,
            modalVisible,
            modalMessage
        };
    },
};
</script>
