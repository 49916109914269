<template>
    <div class="update-log" id="update-log">
        <!-- 选项卡 -->
        <div class="nav nav-tabs mb-4 nav-center" role="tablist">
            <button class="nav-link" id="active" v-for="(item, index) in settings" :key="index"
                :class="{ active: activeTab === index }" @click="activeTab = index">
                {{ item.title }}
            </button>
        </div>

        <!-- 参数区 -->
        <h5>参数设置: 等待时间为毫秒级(ms)</h5>

        <div class="tab-content">
            <div class="tab-pane fade" v-for="(item, index) in settings" :key="index"
                :class="{ show: activeTab === index, active: activeTab === index }">
                <div class="script-settings">
                    <div v-if="index === 0" class="help settingArea">
                        <!-- 需要重复的值 -->
                        <div class="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">三角大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.triangleSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.triangleSize"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.triangleSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">拉动步长:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.pullingStepLength', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.pullingStepLength"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.pullingStepLength', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">合球角度:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.kneeAngle', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.kneeAngle" class="form-control" />
                                <button @click="adjustValue('triangle.kneeAngle', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">分身间隔:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.avatarInterval', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.avatarInterval"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.avatarInterval', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue" id="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">1、摇杆拉到侧边</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joystickSideClone', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joystickSideClone"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joystickSideClone', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">ms进行分身</label>
                        </div>

                        <div class="subvalue" id="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">2、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joykWaitingForClone', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joykWaitingForClone"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joykWaitingForClone', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">ms后到侧边</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joykWaitingForClone_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joykWaitingForClone_2"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joykWaitingForClone_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">ms进行分身</label>
                        </div>

                        <div class="subvalue" id="subvalue">
                            <label for="triangle" class="form-label subtitle-xs">3、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joykWaitingForTarget', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joykWaitingForTarget"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joykWaitingForTarget', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">ms后到目标</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joykWaitingForTarget_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joykWaitingForTarget_2"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joykWaitingForTarget_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">ms进行</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('triangle.joykWaitingForTarget_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.triangle.joykWaitingForTarget_3"
                                    class="form-control" />
                                <button @click="adjustValue('triangle.joykWaitingForTarget_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="triangle" class="form-label subtitle-xs">分</label>
                        </div>
                    </div>

                    <div v-if="index === 1" class="help settingArea">
                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">拖尾按钮大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.trailingButtonSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.trailingButtonSize"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.trailingButtonSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">拉动步长:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.pullingStepLength_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.pullingStepLength_2"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.pullingStepLength_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">合球角度:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.kneeAngle_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.kneeAngle_2"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.kneeAngle_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">分身间隔:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.avatarInterval_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.avatarInterval_2"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.avatarInterval_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">1、摇杆拉动侧边</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.joySideDoubleSplit', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.joySideDoubleSplit"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.joySideDoubleSplit', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="trailing" class="form-label subtitle-xs">进行双分</label>
                        </div>

                        <div class="subvalue">
                            <label for="trailing" class="form-label subtitle-xs">2、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.joyWaitingForTheEnemy"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="trailing" class="form-label subtitle-xs">后到敌人点再</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.joyWaitingForTheEnemy_2"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="trailing" class="form-label subtitle-xs">进行</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.trailing.joyWaitingForTheEnemy_3"
                                    class="form-control" />
                                <button @click="adjustValue('trailing.joyWaitingForTheEnemy_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="trailing" class="form-label subtitle-xs">分</label>
                        </div>
                    </div>

                    <div v-if="index === 2" class="help settingArea">
                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">后仰大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.backwardSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.backwardSize" class="form-control" />
                                <button @click="adjustValue('back.backwardSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">拉动步长:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.pullingStepLength', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.pullingStepLength"
                                    class="form-control" />
                                <button @click="adjustValue('back.pullingStepLength', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">分身间隔:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.avatarInterval', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.avatarInterval" class="form-control" />
                                <button @click="adjustValue('back.avatarInterval', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">1、摇杆拉动到侧边</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickSideClone_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickSideClone_2"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickSideClone_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">ms进行分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">1、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickWaitsForTheOppositeDirection', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickWaitsForTheOppositeDirection"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickWaitsForTheOppositeDirection', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">ms后到反方向后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickWaitsForTheOppositeDirection_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickWaitsForTheOppositeDirection_2"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickWaitsForTheOppositeDirection_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">ms进行分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="back" class="form-label subtitle-xs">3、摇杆等</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickWaitingForTheEnemy"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">ms到敌人点再</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickWaitingForTheEnemy_2"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">ms进行</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.back.joystickWaitingForTheEnemy_3"
                                    class="form-control" />
                                <button @click="adjustValue('back.joystickWaitingForTheEnemy_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="back" class="form-label subtitle-xs">分</label>
                        </div>
                    </div>

                    <div v-if="index === 3" class="help settingArea">
                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">旋转大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.rotationSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.rotationSize"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.rotationSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">拉动步长:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.pullingStepLength', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.pullingStepLength"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.pullingStepLength', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">分身间隔:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.avatarInterval', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.avatarInterval"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.avatarInterval', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">1、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickSideClone', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickSideClone"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickSideClone', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">度</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickSideClone_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickSideClone_2"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickSideClone_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">2、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms后拉动</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_2"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">度后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_3"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">3、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_4', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_4"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_4', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms后拉动</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_5', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_5"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_5', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">度后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_6', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_6"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_6', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">4、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_7', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_7"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_7', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms后拉动</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_8', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_8"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_8', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">度后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForClone_9', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForClone_9"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForClone_9', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="rotation" class="form-label subtitle-xs">5、摇杆等</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForOrigin"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms后拉动到原点</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForOrigin_2"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">ms后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.rotation.joystickWaitingForOrigin_3"
                                    class="form-control" />
                                <button @click="adjustValue('rotation.joystickWaitingForOrigin_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="rotation" class="form-label subtitle-xs">分</label>
                        </div>
                    </div>

                    <div v-if="index === 4" class="help settingArea">
                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">蛇手大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.snakehandSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.snakehandSize"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.snakehandSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">拉动步长:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.pullingStepLength', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.pullingStepLength"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.pullingStepLength', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">蛇手幅度:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.snakehand_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.snakehand_2"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.snakehand_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">分身间隔:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.avatarInterval', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.avatarInterval"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.avatarInterval', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue subvalueWidth">
                            <label for="snakehand" class="form-label subtitle-xs">1、摇杆拉动到敌人点后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickEnemyClones', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickEnemyClones"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickEnemyClones', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">ms进行分身</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickEnemyClones_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickEnemyClones_2"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickEnemyClones_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">2、摇杆等待</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickWaitSide', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickWaitSide"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickWaitSide', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">ms后拉动侧边后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickWaitSide_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickWaitSide_2"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickWaitSide_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">ms进行分身</label>
                        </div>

                        <div class="subvalue">
                            <label for="snakehand" class="form-label subtitle-xs">3、摇杆</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickWaitingForTheEnemy"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">ms拉动到敌人点后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy_2', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickWaitingForTheEnemy_2"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy_2', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">ms后</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy_3', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.snakehand.joystickWaitingForTheEnemy_3"
                                    class="form-control" />
                                <button @click="adjustValue('snakehand.joystickWaitingForTheEnemy_3', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                            <label for="snakehand" class="form-label subtitle-xs">分</label>
                        </div>
                    </div>

                    <div v-if="index === 5" class="help settingArea">
                        <div class="subvalue">
                            <label for="macro" class="form-label subtitle-xs">吐球大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('macro.ballSize', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.macro.ballSize" class="form-control" />
                                <button @click="adjustValue('macro.ballSize', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="macro" class="form-label subtitle-xs">吐球速度:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('macro.ballSpittingSpeed', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.macro.ballSpittingSpeed"
                                    class="form-control" />
                                <button @click="adjustValue('macro.ballSpittingSpeed', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="macro" class="form-label subtitle-xs">16分大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('macro.points16', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.macro.points16" class="form-control" />
                                <button @click="adjustValue('macro.points16', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="macro" class="form-label subtitle-xs">4分大小:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('macro.points4', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.macro.points4" class="form-control" />
                                <button @click="adjustValue('macro.points4', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>

                        <div class="subvalue">
                            <label for="macro" class="form-label subtitle-xs">4分速度:</label>
                            <div class="d-flex align-items-center areaWidth">
                                <button @click="adjustValue('macro.pointsSpeed4', -1)"
                                    class="btn btn-primary btn-vsm me-2">-</button>
                                <input type="number" v-model.number="params.macro.pointsSpeed4" class="form-control" />
                                <button @click="adjustValue('macro.pointsSpeed4', 1)"
                                    class="btn btn-primary btn-vsm ms-2">+</button>
                            </div>
                        </div>
                    </div>

                    <button @click="submit(index)" class="btn btn-primary mt-3 save">加载</button>

                    <MyModal :visible="modalVisible" :message="modalMessage" @close="modalVisible = false" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import MyModal from '../public/MyModal';

export default {
    name: 'ParameterConfig',
    components: { MyModal },
    props: {
        username: {
            type: String,
            required: true,
        },
        ballConfig: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const params = ref({
            triangle: {
                triangleSize: 130,
                pullingStepLength: 100,
                kneeAngle: 80,
                avatarInterval: 2,
                joystickSideClone: 60,
                joykWaitingForClone: 0,
                joykWaitingForClone_2: 60,
                joykWaitingForTarget: 0,
                joykWaitingForTarget_2: 60,
                joykWaitingForTarget_3: 60,
            },
            trailing: {
                trailingButtonSize: 130,
                pullingStepLength_2: 60,
                kneeAngle_2: 45,
                avatarInterval_2: 2,
                joySideDoubleSplit: 60,
                joyWaitingForTheEnemy: 0,
                joyWaitingForTheEnemy_2: 60,
                joyWaitingForTheEnemy_3: 60,
            },
            back: {
                backwardSize: 130,
                pullingStepLength: 100,
                avatarInterval: 10,
                joystickSideClone_2: 60,
                joystickWaitsForTheOppositeDirection: 17,
                joystickWaitsForTheOppositeDirection_2: 60,
                joystickWaitingForTheEnemy: 17,
                joystickWaitingForTheEnemy_2: 60,
                joystickWaitingForTheEnemy_3: 20,
            },
            rotation: {
                rotationSize: 130,
                pullingStepLength: 90,
                avatarInterval: 10,
                joystickSideClone: 35,
                joystickSideClone_2: 35,
                joystickWaitingForClone: 17,
                joystickWaitingForClone_2: 0,
                joystickWaitingForClone_3: 60,
                joystickWaitingForClone_4: 17,
                joystickWaitingForClone_5: -35,
                joystickWaitingForClone_6: 60,
                joystickWaitingForClone_7: 17,
                joystickWaitingForClone_8: -60,
                joystickWaitingForClone_9: 60,
                joystickWaitingForOrigin: 17,
                joystickWaitingForOrigin_2: 60,
                joystickWaitingForOrigin_3: 20,
            },
            snakehand: {
                snakehandSize: 130,
                pullingStepLength: 110,
                snakehand_2: 65,
                avatarInterval: 10,
                joystickEnemyClones: 60,
                joystickEnemyClones_2: 17,
                joystickWaitSide: 60,
                joystickWaitSide_2: 60,
                joystickWaitingForTheEnemy: 17,
                joystickWaitingForTheEnemy_2: 60,
                joystickWaitingForTheEnemy_3: 20,
            },
            macro: {
                ballSize: 180,
                ballSpittingSpeed: 70,
                points16: 130,
                points4: 130,
                pointsSpeed4: 50
            }
        });

        const settings = [
            { title: '三角设置区' },
            { title: '拖尾设置区' },
            { title: '后仰设置区' },
            { title: '旋转设置区' },
            { title: '蛇手设置区' },
            { title: '连点宏设置区' }
        ];

        const modalVisible = ref(false); // 控制弹窗显示
        const modalMessage = ref(''); // 存储弹窗消息

        const activeTab = ref(0);
        let debounceTimeout = null; // 防抖定时器

        // 从后端获取数据
        const fetchParams = async () => {
            console.log(props.ballConfig);
            try {
                const response = await axios.get(`https://154.44.25.22/configInfo.php?username=${props.username}&ballConfig=${props.ballConfig}`);
                if (response.data.success) {
                    response.data.data.forEach(item => {
                        const category = item.category.toLowerCase();
                        if (!params.value[category]) {
                            params.value[category] = {};
                        }
                        // 将值转换为数字
                        params.value[category][item.param_name] = Number(item.param_value);
                    });
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const adjustValue = (key, delta) => {
            const keys = key.split('.');
            let value = params.value;

            for (let i = 0; i < keys.length - 1; i++) {
                value = value[keys[i]];
            }

            const lastKey = keys[keys.length - 1];
            value[lastKey] = value[lastKey] + delta;
        };

        const submit = (index) => {
            const platform = window.platform;
            if (platform != null && platform.scriptSubmit != null) {
                var configJson = JSON.stringify({
                    triangle: params.value['triangle'],
                    trailing: params.value['trailing'],
                    back: params.value['back'],
                    rotation: params.value['rotation'],
                    snakehand: params.value['snakehand'],
                    macro: params.value['macro'],
                });
                console.log(configJson)
            }

            if (debounceTimeout) {
                modalMessage.value = '请稍等，正在处理上一个请求...';
                modalVisible.value = true;
                return;
            }

            debounceTimeout = setTimeout(async () => {
                const currentParams = { ...params.value }; // 复制当前参数

                // 检查 username 是否存在，如果不存在则使用默认值
                currentParams.username = currentParams.username ?? props.username;
                currentParams.ballConfig = currentParams.ballConfig ?? props.ballConfig;
                console.log(currentParams)

                console.log(`提交区域 ${index + 1} 的数据:`, currentParams);
                // 发送请求
                try {
                    const response = await fetch('https://154.44.25.22/save.php', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(currentParams) // 发送包括 ballConfig 的数据
                    });
                    const data = await response.json();
                    modalMessage.value = '加载成功';
                    modalVisible.value = true;
                    console.log('成功:', data);
                } catch (error) {
                    modalMessage.value = '加载失败';
                    modalVisible.value = true;
                    console.error('错误:', error);
                } finally {
                    debounceTimeout = null; // 重置防抖定时器
                }
            }, 300); // 3毫秒防抖
        };


        // 在组件挂载时获取参数数据
        onMounted(fetchParams);

        return {
            params,
            settings,
            activeTab,
            adjustValue,
            submit,
            modalMessage,
            modalVisible
        };
    }
};
</script>
